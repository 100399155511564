/*
* BASE DEPENDENCIES
*/

@import "abstracts/mixins", "abstracts/functions", "abstracts/vars";

.error-404__flair {
	font-size: 28vw;
	font-family: $font-headings;
	color: $color-brand;

	@include min($max-width) {
		font-size: 320px;
	}
}
